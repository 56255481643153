.main-layout .main-layout-content {
  margin: 24px 16px 0;
  min-height: 84vh;
}

@media screen and (max-width: 692px) {
  .main-layout .header-user-email {
    display: none;
  }
}

.upload-wrapper {
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 10px;
}

.upload-wrapper:focus {
  box-shadow: 1px 2px 8px -8px;
}

.upload-input:focus {
  box-shadow: 0px 0px 3px -1px;
}
